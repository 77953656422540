<template>
  <div class="container">
    

    <!-- Background image -->
    <div
        class="d-flex justify-content-center align-items-center"
        style="height: 100vh"
      >
        <div class="text-center">
          <img
            class="mb-4"
            src="https://cdn.simplexitydynamics.com/icons/cg/android-chrome-512x512.png"
            style="width: 208px; height: 176px"
          />
          <h5 class="mb-3">{{ msg }}</h5>
          <p class="mb-3">---</p>
          <p class="mt-5 mb-3">
            Testing server front end capabilities.
          </p>

        </div>
      </div>
    <!-- Background image -->
    

  </div>

</template>
  
<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}
/* Height for devices larger than 576px */
@media (min-width: 992px) {
  #intro {
    margin-top: -58.59px;
  }
}
</style>