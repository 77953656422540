import { 
    createRouter, 
    createWebHashHistory 
} from "vue-router";

import HomePage from '../components/HomePage';
import NewsPage from '../components/NewsPage';


export default createRouter({
    history: createWebHashHistory(),
    routes: [{
        path: '/',
        name: 'Home',
        component: HomePage,
    },{
        path: '/news',
        name: 'news',
        component: NewsPage,
    }],
});