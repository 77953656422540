<template>
  <!-- Footer -->
  <footer class="text-center text-lg-start bg-light text-muted">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">
        <span>Get connected with us on social networks:</span>
      </div>
      <!-- Left -->

      <!-- Right -->
      <div>
        <a href="https://www.facebook.com/CoolGrabs/" class="me-4 text-reset">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="https://twitter.com/Cool_Grabs" class="me-4 text-reset">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/c00lgrabs/" class="me-4 text-reset">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/company/cool-grabs" class="me-4 text-reset">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://www.youtube.com/@coolgrabs" class="me-4 text-reset">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <i class="fas fa-gem me-3"></i>Cool Grabs
            </h6>
            <p>
              <i class="fa-solid fa-barcode fa-fade"></i> We have been dreaming of this marketplace for a long time now.  Excited to be finally working on this. <i class="fa-solid fa-bolt fa-shake"></i>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <!-- <h6 class="text-uppercase fw-bold mb-4">
              Coverage
            </h6>
            <p>
              <a href="#!" class="text-reset">Algorithms, Frameworks</a>
            </p>
            <p>
              <a href="#!" class="text-reset">UI / UX</a>
            </p>
            <p>
              <a href="#!" class="text-reset">AI / ML</a>
            </p>
            <p>
              <a href="#!" class="text-reset">DevSecDataOps</a>
            </p> -->
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4">
              Partner Sites
            </h6>
            <p>
              <a href="https://huttstudio.com/" class="text-reset">Hutt Studio</a>
            </p>
            <p>
              <a href="https://simplexity101.com/" class="text-reset">Simplexity 101</a>
            </p>
            <p>
              <a href="https://codersinaction.com/" class="text-reset">Coders in Action</a>
            </p>
            <p>
              <a href="https://stateoforder.com/" class="text-reset">State of Order</a>
            </p>
            <p>
              <a href="https://tychenet.com/" class="text-reset">Tyche Net</a>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <h6 class="text-uppercase fw-bold mb-4"><i class="fas fa-location-dot me-3"></i>Contact</h6>
            <p> 
              Simplexity Dynamics <br/> 
              Level 2 Bell Gully Building<br/>
              40 Lady Elizabeth Lane, Wellington 6011
            </p>
            
            
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2023 Copyright:
      <a class="text-reset fw-bold" href="https://simplexitydynamics.com/">
        Simplexity Dynamics
      </a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->

</template>

<script>
export default {
  name: 'NavFooter',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
