<template>  
  <header>
   <NavTop />
 </header>
 <!-- TODO: Left pane navigation -->
 
 <main>
   <!-- <HomePage msg="Hutt Studio"/> -->
   <router-view/>
 </main>

 <footer class="text-center text-lg-start bg-light text-muted">
   <NavFooter />
 </footer>
</template>

<script>
// import HomePage from './components/HomePage.vue'
import NavTop from './components/NavTop.vue'
import NavFooter from './components/NavFooter.vue'

export default {
 name: 'App',
 components: {
   // HomePage,
   NavTop,
   NavFooter

 }
}
</script>

<style>
#app {
 font-family: Roboto, Helvetica, Arial, sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 text-align: center;
 color: #2c3e50;
 margin-top: 0px;
}
</style>
